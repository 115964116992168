export class UserProfile {
    id: string;
    altura: number;
    code: string;
    edad: number;
    link_img: string;
    link_video: string;
    peso: number;
    position: string;
    provincia: string;
    slug: string;
    fecha_nacimiento: string;
    pasaporte: number;
    updated_at: string;
    club: string;
    historia: string;
    name: string;
    club_img: string;
    profile_img: string;
    market_value: number;
    salary_current: number;
    age: string;
    categoria: string;
    dob: string;
    contract_end_date: string;
    has_passport: number;
    country1: string;
    country2: string;
    laterality: string;
    pos_color: string;
    height: number;
    weight: number;
    intro: string
}