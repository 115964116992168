import { Component, OnInit } from '@angular/core';
import { UserService } from './../../services/user.service';
import { UserProfileService } from './../../user-profile/user-profile.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthenticationService } from '../../shared/services/authentication.service'
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  constructor(public userService: UserService, public userProfileService: UserProfileService, private router: Router, public authenticationService: AuthenticationService) { }
  public login = {
    email: '',
    password: ''
  };
  ngOnInit() {
    if (this.userService.token !== null) {
      this.router.navigate(['/user-lists']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }
  loginForm() {
    this.userService.getLogin(this.login.email, this.login.password).subscribe(res => {
      this.login.email = '';
      this.login.password = '';
      if (res == null || res.length == 0) {
        this.authenticationService.presentToast('Datos de acceso incorrectos.');
        return
      }
      if (res.remember_token.length > 0 && res.remember_token !== null) {
        this.userService.isBackground = true;
        this.userService.token = res.nombre;
        this.userProfileService.userId = res.id;
        localStorage.setItem('userId', res.id);
        localStorage.setItem('token', res.remember_token);
        this.userService.user = res.nombre;
        localStorage.setItem('user', res.nombre);
        this.router.navigate(['/user-lists']);
        this.authenticationService.presentToast(`${res.nombre} is logged in successfully`);
      }
    }, (err) => {
      console.log(err)
      this.authenticationService.presentToast(err.error.message);
    });
  }

  goLink(url) {
    window.open(url, '_self');
  }
}
