import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfile } from '../user-profile/user-profile';
import { UserProfileService } from './user-profile.service';
import { PageStatusService } from '../services/page-status';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationComponent } from '../navigation/navigation.component';
declare var $: any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	public openedCategory: boolean[] = [];
	public openedSelectDialog: boolean[] = [];
	public jugador: UserProfile;
	public slug: string;
	public is_details_loaded = false;
	public comeOutAnimation = "fadeIn";
	public ball_index = -1;
	public ballName = [
		'Perfil',
		'Trayectoria',
		'Biografía',
		'Imágenes Videos',
		'Valuación',
		'Redes',
	];

	public iconName = [
		'info_outline',
		'trending_up',
		'share',
		'image_outline',
		'share',
		'share'
	];

	public categoryName = [
		'Perfil',
		'Trayectoria',
		'Biografía',
		'ImágenesVideos',
		'Valuación',
		'Redes',
	];
	public dialogName = [
		'confirm',
		'done',
	];
	public mouseDown: boolean = false;
	public preX: any;
	public curX: any;
	public eventUp: any;
	public eventDown: any;
	public interval;
	public index;
	public selected_category;
	public selected_dialog;
	public selectedPlayers: UserProfile[] = [];
	public selectedIds: string[] = [];
	public serverUrl: string = environment.apiUrl;
	public selectePlayerName: string = '';
	public playerCount: number = 0;
	public title: string = '';
	public message: string = '';
	public profileUrl = window.location.href;

	public achievement = null;
	public history = null;
	public social = null;

	public flag: boolean = false;

	public carouselItem: string = '';

	@ViewChild("categoryList") category: ElementRef;
	@ViewChild("next") next: ElementRef;
	@ViewChild("previous") previous: ElementRef;
	@ViewChild("next_category") next_category: ElementRef;
	@ViewChild("previous_category") previous_category: ElementRef;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public userProfileService: UserProfileService,
		private pageStatusService: PageStatusService,
		private navigationComponent: NavigationComponent,
	) {
		route.params.subscribe(val => {
			clearInterval(this.interval);
			this.format();
		});
	}

	ngOnInit() {
		this.selected_dialog = -1;
		for (let i = 0; i < 6; i++) {
			this.openedCategory.push(false);
		}
		for (let i = 0; i < 2; i++) {
			this.openedSelectDialog.push(false);
		}
	}

	goBack() {
		this.navigationComponent.gotoHome();
	}

	format(type = 0) {
		this.selected_category = -1;
		this.ball_index = -1;
		this.index = 7;
		setTimeout(() => this.index = -1, 1450);
		this.interval = setInterval(() => {
			this.ball_index++;
			this.index++;
			setTimeout(() => {
				if (this.next) {
					let el: HTMLElement = this.next.nativeElement as HTMLElement;
					el.click();
				}
			}, 1500);
			if (this.ball_index == 6) {
				this.ball_index = 0;
				setTimeout(() => {
					this.index = 7;
					setTimeout(() => this.index = 0, 10);
				}, 1000);
			}
		}, 1500);
		this.pageStatusService.setStatus('user-profile');
		this.slug = this.route.snapshot.params['slug'];
		for (let i = 0; i < 6; i++) {
			this.openedCategory[i] = false;
		}
		this.is_details_loaded = false;
		this.userProfileService.getUserProfile(this.slug, type).subscribe(jugador => {
			this.jugador = jugador;
			this.is_details_loaded = true;
			this.slug = this.jugador.slug;
			setTimeout(() => this.openedCategory[0] = true, 750);
			setTimeout(() => this.openedCategory[1] = true, 1500);
			setTimeout(() => this.openedCategory[2] = true, 2250);
		})
		this.userProfileService.getUserMedia(this.slug, type).subscribe(res => {
			this.userProfileService.images = res;
			this.userProfileService.images.forEach(element => {
				if (element.path.match(new RegExp("https://youtu.be/", "i"))) {
					element.fileType = 'video';
					element.path = element.path.replace(new RegExp("https://youtu.be/", "i"), "https://www.youtube.com/embed/");
				}
				else {
					element.fileType = 'image';
					element.path = this.serverUrl + '/' + element.path;
				}
			});
		})

		this.getHistory(this.slug);
		this.getSports();
		this.getSocial(this.slug);
		this.getAchievement(this.slug);
	}

	getHistory(slug: string) {
		this.userProfileService.getHistory(slug).subscribe(res => {
			this.history = res
		})
	}

	getSports() {
		let userId = localStorage.getItem('userId')
		this.userProfileService.getLoggedUser(userId).subscribe(res => {
			console.log('sports:', res)
		})
	}

	getSocial(slug: string) {
		this.userProfileService.getUserSocial(slug).subscribe(res => {
			this.social = res
		})
	}

	getAchievement(slug: string) {
		this.userProfileService.getAchievement(slug).subscribe(res => {
			this.achievement = res
		})
	}
	getUbicacion(provincia, code) {
		if (provincia && code) {
			return provincia + " " + code;
		}
		return "";
	}

	formatHeight(num) {
		let str: string = num.toString();
		let retval = "";
		for (let i = str.length - 1; i >= 0; i--) {
			if ((str.length - i) == 3) retval = ',' + retval;
			retval = str[i] + retval;
		}
		return retval;
	}

	previousProfile() {
		this.userProfileService.getUserProfile(this.slug, 1).subscribe(jugador => {
			if (jugador.slug)
				this.router.navigate(['/user-profile/', jugador.slug])
		});
	}

	nextProfile() {
		this.userProfileService.getUserProfile(this.slug, 2).subscribe(jugador => {
			if (jugador.slug)
				this.router.navigate(['/user-profile/', jugador.slug])
		});
	}

	gotoUserList() {
		this.router.navigate(['/']);
	}

	selectCategory(cate_index) {
		this.flag = true
		this.selected_category = cate_index;
		setTimeout(() => {
			for (let i = 0; i < this.selected_category; i++) {
				let el: HTMLElement = this.next_category.nativeElement as HTMLElement;
				el.click();
			}
		}, 1);
	}

	onMouseUp(e) {
		this.eventUp = e;
		this.curX = e.clientX;
		if (this.mouseDown) {
			if (Math.abs(e.clientX - this.preX) > 30) {
				// Si no es la ultima
				if (this.selected_category <= 6) {
					if (Math.abs(e.clientX - this.preX) < 450) {
						if (this.preX > e.clientX) {
							let el: HTMLElement = this.next_category.nativeElement as HTMLElement;
							el.click();
							this.selected_category++;
						}
					}
				}
			}
			// Si no es la primera
			if ( this.selected_category > 0) {
				if (e.clientX - this.preX >= 0) {
					if (Math.abs(e.clientX - this.preX) <= 30) {
						let el: HTMLElement = this.next_category.nativeElement as HTMLElement;
						el.click();
					}
					else {
						this.selected_category--;
					}
				}
			}
			this.mouseDown = false;
		}
	}

	onSelectedItem(e) {
		this.carouselItem = e.item
	}

	onMouseDown(e) {
		if (this.carouselItem != 'Redes') {
			this.eventDown = e;
			this.mouseDown = true;
			this.preX = e.clientX;
		}
	}

	closeCategory() {
		this.selected_category = -1;
		this.flag = false
	}

	selectPlayer() {
		if (!this.selectedPlayers.some(item => item.id == this.jugador.id)) {
			this.selectedPlayers.push(this.jugador);
			this.confirmPlayer()
		}
	}

	confirmPlayer() {
		this.selected_dialog = 0;
		this.selectePlayerName = '';
		this.playerCount = this.selectedPlayers.length;
		this.selectedPlayers.forEach(element => {
			this.selectePlayerName = this.selectePlayerName + (this.selectePlayerName ? ', ' : '') + element.name;
		});
	}

	share() {
		this.selected_dialog = 2;
		
		this.profileUrl = 'www.clickpassgoal.com' + window.location.pathname;
		console.log(this.profileUrl)
	}
	requestPlayer() {
		this.selectedPlayers.forEach(element => {
			this.selectedIds.push(element.id);
		});
		this.userProfileService.requestPlayer(this.selectedIds).subscribe(
			(res: any) => {
				this.selected_dialog = 1;
				this.title = "Gracias por enviar tu solictud"
				this.message = "Nuestros resentantes asociados se pondran encontacto a la brevedad.";
			},
			(err: HttpErrorResponse) => {
				this.selected_dialog = 1;
				this.title = "Gracias por enviar tu solictud"
				this.message = "Nuestros resentantes asociados se pondran encontacto a la brevedad.";
			}
		);
	}

	copySiteUrl(text) {
		console.log(text)
		this.selected_dialog = -1;
		this.selectedPlayers = [];
		let listener = (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (text));
			e.preventDefault();
		};

		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	cancelRequest() {
		this.selected_dialog = -1
		this.selectedPlayers = [];
	}
	requestPlayerFinish() {
		this.selected_dialog = -1;
		this.selectedPlayers = [];
	}
	onMoveLink(link) {
		window.open(link, '_system', 'location=yes');
	}


}
