import { Component, OnInit, Output, EventEmitter, Input, HostListener, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FilterService } from './filter.service';
import { PageStatusService } from '../../services/page-status';
import { UserService } from '../../services/user.service';
import { UserProfileService } from './../../user-profile/user-profile.service'
@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {

    fromAges = ['17', '18', '19', '20', '21', '22', '23', ' 24', '25', '26', '27', '28', '29', '30'];
    toAges = ['18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38'];
    fromWeights = ['50', '60', '70', '80'];
    toWeights = ['60', '70', '80', '90', '100', '120'];
    fromHeights = ['150', '160', '170', '180', '190', '200'];
    toHeights = ['170', '180', '190', '200', '210'];
    posicion: string;
    positionDatas: any;
    is_details_loaded: boolean = false;
    menu_opened: boolean = false;
    header_flag: boolean = false;
    sidebar = null;
    public genero = 1;
    public isStyle: boolean;
    public isGender: boolean;
    public isManActive: boolean = false;
    public isWomanActive: boolean = false;
    public sports;
    public sportId: number = 1;
    public sidebarStatus: string = ''

    filterForm = new FormGroup({
        search: new FormControl(''),
        genero: new FormControl(this.genero),
        fromAge: new FormControl('17'),
        toAge: new FormControl('38'),
        position: new FormControl('0'),
        fromHeight: new FormControl('150'),
        toHeight: new FormControl('200'),
        fromWeight: new FormControl('50'),
        toWeight: new FormControl('120'),
        video: new FormControl(false),
        sport_id: new FormControl('0'),
    });

    @Input('search') search: string;

    @Output() filterData = new EventEmitter<FormGroup>();
    @Output() menuChanged = new EventEmitter<boolean>();

    @ViewChild("filterMenu", { read: ElementRef }) filterMenu: ElementRef;

    @HostListener('document:click', ['$event'])

    clickout(event) {

        if (this.filterMenu.nativeElement.contains(event.target)) {
            this.menuChanged.emit(this.menu_opened);
        } else if (event == 'expand') {
            this.header_flag = !this.header_flag
            if (this.menu_opened) {
                this.menuChanged.emit(this.header_flag);
                this.menu_opened = false
            } else {
                this.menuChanged.emit(this.header_flag);
                this.menu_opened = true
            }
        } else if (event == 'collapse') {
            this.header_flag = !this.header_flag
            if (this.menu_opened) {
                this.menuChanged.emit(this.header_flag);
                this.menu_opened = false
            } else {
                this.menuChanged.emit(this.header_flag);
                this.menu_opened = true
            }
        } else if(event.target.className == "mat-option-text") {
            this.menu_opened = true
            this.menuChanged.emit(this.menu_opened);
        }
        else {
            this.menu_opened = false
            this.menuChanged.emit(this.menu_opened);
        }
    }

    constructor(private router: Router,
        private filterService: FilterService,
        public pageStatusService: PageStatusService,
        public userService: UserService,
        public userProfileService: UserProfileService,) {
        this.onGetPosition(this.sportId)
    }
    ngOnInit() {
        this.userService.currentMessage.subscribe(message => {
            this.sidebarStatus = message
            this.clickout(message)
        })

        if (this.userService.token !== null) {
            this.userService.isBackground = true;
        }

        let userId = localStorage.getItem('userId')
        this.userProfileService.getLoggedUser(userId).subscribe(res => {

            this.sports = res;
        })

    }
    getSportsByName(sportId) {
        return this.sports.filter(sport => sport.id == sportId);
    }

    onOptionsSelected(sportId) {
        this.onGetPosition(sportId)
        const name = this.getSportsByName(sportId)
        if (name.length > 0) {
            this.userService.changeIndicator(name[0].name)
        } else {
            this.userService.changeIndicator('default')
        }
    }

    onSubmit() {
        this.menu_opened = false;
        this.menuChanged.emit(this.menu_opened);
        this.filterData.emit(this.filterForm);
        // this.router.navigate(['/user-lists/', this.filterForm.controls]);
    }

    onReset() {
        this.filterForm.setValue({
            search: '',
            genero: this.genero,
            fromAge: '17',
            toAge: '38',
            position: '0',
            fromHeight: '150',
            toHeight: '200',
            fromWeight: '50',
            toWeight: '120',
            video: false,
            sport_id: '0',
        })

        this.router.navigate(['/user-lists/', this.filterForm]);
        this.menu_opened = false;
        this.menuChanged.emit(this.menu_opened);
        this.userService.changeIndicator('default')
    }

    onGetPosition(sportId) {
        this.is_details_loaded = false;
        this.filterService.getPosition(sportId).subscribe(position => {
            const entries = Object.entries(position);
            this.positionDatas = entries;
            this.is_details_loaded = true;
            this.filterForm.get("position").setValue('0')
        })
    }

    ngOnChanges() {

    }

    onClickPopupButton() {
        this.menu_opened = !this.menu_opened;
        this.menuChanged.emit(this.menu_opened);
    }
    onChangeSidebar(flag) {
        this.sidebar = flag
        this.menu_opened = !this.menu_opened;
        this.menuChanged.emit(this.menu_opened);
    }
    manGender() {
        this.isGender = true;
        this.isManActive = true;
        this.isWomanActive = false;
        this.genero = 1;
    }
    womanGender() {
        this.isGender = false;
        this.isWomanActive = true;
        this.isManActive = false;
        this.genero = 2;
    }
}