import { Injectable, OnInit } from '@angular/core';

import {HttpHeaders } from '@angular/common/http';


import { CookieService } from 'ngx-cookie-service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  constructor(private cookieService: CookieService, public toastCtrl: ToastController){
  }
  public isAuthenticated(){
		this.cookieService.set('test', 'asas');
    const remember = this.cookieService.get('remember_manager_59ba36addc2b2f9401580f014c7f58ea4e30989d');
    return true ;
    //return remember? true : false;
  }

  async presentToast(msg, pos = '') {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 5000,
      position: pos ? 'top' : 'bottom',
    });

    toast.present();
  }
}
