import { Component, OnInit } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from './../services/user.service';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	public isActive: boolean = false;
	public indicator: string = 'default';
	constructor(
		private navigationComponent: NavigationComponent,
		private location: Location,
		public userService: UserService,
		public router: Router,
	) {

	}

	ngOnInit() {
		if (this.userService.token !== null) {
			this.userService.isBackground = true;
		}
		this.userService.currentIndicator.subscribe(res => {
			this.indicator = res
		})
	}

	gotoHome() {
		this.navigationComponent.gotoHome();
	}

	goBack() {
		this.navigationComponent.gotoHome();
	}
	Active() {
		this.isActive = !this.isActive;
	}
	logout() {
		localStorage.removeItem('token');
		this.userService.token = null;
		this.router.navigate(['/login']);
		this.userService.isBackground = false;
		this.isActive = false;
	}
	changeSidebar(num: string) {
		if (num == '1') {
			this.userService.changeMessage("expand")
		} else {
			this.userService.changeMessage("collapse")
		}
	}

	onChangeSidebar(data) {

	}

}
