import { Injectable, OnInit } from '@angular/core';
import { environment } from '../environment/environment';
import { AppHttpClient } from '../http-client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AppHttpClient {

  public isBackground: boolean;
  public user = localStorage.getItem('user');

  public token = localStorage.getItem('token');
  public apiURL = environment.serverUrl + '/manager';
  // public apiURL = 'http://localhost:8000/api/manager';
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private indicatorSource = new BehaviorSubject('default');
  currentIndicator = this.indicatorSource.asObservable();

  getLogin($email: string, $password: string) {
    let url = this.apiURL + '/login';
    let postData = {
      'email': $email,
      'password': $password
    };
    return this.post(url, postData);
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeIndicator(data: string) {
    this.indicatorSource.next(data)
  }
}
